<template>
    <el-dropdown trigger="click" class="mr-2" placement="bottom">
        <el-button type="primary">
            {{ $t('economy.menu') }}<i class="el-icon-arrow-down el-icon--right" />
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :disabled="!userCan('create invoices')" @click.native="createNewInvoices">
                {{ $t('economy.create_new') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="!canSendEmail" @click.native="sendToClient">
                {{ $t('economy.send_to_client') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
                {{ $t('economy.send_to_different_email') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
                {{ $t('economy.credit') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="!canGenerateRutFiles" divided @click.native="generateRutFiles">
                {{ $t('economy.generate_rut_files') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="!canGenerateAutogiroFiles" @click.native="generateAutogiroFiles">
                {{ $t('economy.generate_autogiro_files') }}
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="toPdf(true)">
                {{ $t('economy.print') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
                {{ $t('economy.print_list') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
                {{ $t('economy.download_pdf') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
                {{ $t('economy.download_pdf_list') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="true">
                {{ $t('economy.download_excel') }}
            </el-dropdown-item>
            <el-dropdown-item :disabled="!userCan('delete invoices') || !canDeleteInvoices" divided @click.native="deleteInvoice">
                {{ $t('common.delete') }}
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="sendToFortnox">
                Send to Fortnox
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import map from 'lodash/map';

export default {
    computed: {
        selectedStatus() {
            return this.$store.state.economy.selectedStatus;
        },
        selectedInvoices() {
            return this.$store.state.economy.selectedInvoices;
        },
        canSendEmail() {
            return this.selectedStatus !== 'draft' && this.selectedInvoices.length > 0;
        },
        canGenerateRutFiles() {
            return this.selectedStatus === 'paid' && this.selectedInvoices.length > 0;
        },
        canGenerateAutogiroFiles() {
            return this.selectedStatus !== 'draft' && this.selectedInvoices.length > 0;
        },
        canDeleteInvoices() {
            return this.selectedStatus === 'draft' && this.selectedInvoices.length > 0;
        },
    },

    methods: {
        createNewInvoices() {
            this.$emit('showModalCreateInvoice');
        },

        async sendToClient() {
            this.$wait.start('sending');

            const invoices = map(this.selectedInvoices, 'uuid');

            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/send_email`, {
                invoices,
            });

            this.$wait.end('sending');
            this.$emit('change');
            this.$notify.success({ title: this.$t('common.success') });
        },

        async toPdf(toPrint = true) {
            const invoices = map(this.selectedInvoices, 'uuid');

            const { data } = await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/invoice_pdf_sign_route`, {
                invoice_uuid: invoices,
                print:        toPrint,
            });

            window.open(data);
            // window.open(data + '?id=' + invoices);
        },

        async generateRutFiles() {
            const invoices = map(this.selectedInvoices, 'uuid');

            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/rut_files`, {
                invoice_uuid: invoices,
            });

            this.$notify.success({ title: 'Success' });
        },

        async generateAutogiroFiles() {
            const invoices = map(this.selectedInvoices, 'uuid');

            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/autogiro_files/generate`, {
                invoice_uuid: invoices,
            });

            this.$notify.success({ title: 'Success' });
        },


        async sendToFortnox() {
            const invoices = map(this.selectedInvoices, 'uuid');

            this.$wait.start('updating');
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices/send_to_fortnox`, {
                invoices: invoices,
            });

            this.$wait.end('updating');
            this.$notify.success({ title: this.$t("common.success") });
        },

        deleteInvoice() {
            this.$confirm(this.$t('common.are_you_sure'), this.$t('common.warning'), {
                confirmButtonText: this.$t('common.yes'),
                cancelButtonText:  this.$t('common.cancel'),
                type:              'warning',
                beforeClose:       async (action, instance, done) => {
                    if (action === 'confirm') {
                        // @Filip
                        instance.confirmButtonLoading = true;
                        instance.closeOnClickModal = false;
                        instance.showCancelButton = false;
                        instance.closeOnPressEscape = false;

                        this.$wait.start('sending');

                        const invoices = map(this.selectedInvoices, 'uuid');

                        await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/economy/invoices`, {
                            params: {
                                invoices,
                            },
                        });
                        this.$notify.success({ title: 'Success' });
                        this.$emit('change');

                        this.$wait.end('sending');
                        done();
                        instance.confirmButtonLoading = false;
                        instance.closeOnClickModal = true;
                        instance.showCancelButton = true;
                        instance.closeOnPressEscape = true;
                    } else {
                        done();
                    }
                },
            }).catch(() => {});
        },
    },
};
</script>
